<template>
  <div id="index">
    <dv-full-screen-container class="bg">
      <div class="host-body">
        <Menu name="person-realname"
              @change="onProInfoChange($event)" />

        <dv-loading v-if="loading">Loading...</dv-loading>
        <div v-else
             class="body-box">
          <!-- 第三行数据 -->
          <div class="content-box">
            <div>
              <dv-border-box-1>
                <div id="worktypepie">
                  <div class="bg-color-black">
                    <div>
                      <div>
                        <Echart :options="worktypeoptions"
                                id="centreLeft1Chart"
                                height="5.05rem"
                                width="5.75rem"></Echart>
                      </div>
                    </div>
                  </div>
                </div>
              </dv-border-box-1>
            </div>

            <div>
              <dv-border-box-8>
                <div class="attendanceRate">
                  <div class="down">
                    <div class="percent">
                      <div class="item bg-color-black">
                        <PieCicleChart :id="personCount[0].id"
                                       :seriesData="personCount[0].seriesData"
                                       :text="personCount[0].text"
                                       unit="人"
                                       :showlegend="true" />
                      </div>
                      <div class="item bg-color-black">
                        <PieCicleChart :id="personCount[1].id"
                                       :seriesData="personCount[1].seriesData"
                                       :text="personCount[1].text"
                                       unit="人" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="attendanceRate">
                  <div class="down">
                    <div class="percent">
                      <div class="item bg-color-black">
                        <CenterChart :id="rate[0].id"
                                     :tips="rate[0].tips"
                                     :text="rate[0].text"
                                     :colorObj="rate[0].colorData" />
                      </div>

                      <div class="item bg-color-black">
                        <CenterChart :id="rate[1].id"
                                     :tips="rate[1].tips"
                                     :text="rate[1].text"
                                     :colorObj="rate[1].colorData" />
                      </div>
                    </div>
                  </div>
                </div>
              </dv-border-box-8>
            </div>
            <div>
              <dv-border-box-8>
                <div class="attendanceRate">
                  <div class="down">
                    <div class="percent">
                      <vipCard options="cardColorNone">
                        <!-- <vipCard > -->
                        <div class="headerBox"
                             slot="header">
                          <div class="headerImgname">
                            <div class="face">
                              <img alt=""
                                   style="width: 100%"
                                   :src="this.attendancePerson.resource" />
                            </div>
                            <div class="card-info"
                                 style="">
                              <div class="name">
                                姓名:<span>{{
                                  this.attendancePerson.name
                                }}</span>
                              </div>
                              <div class="vipka">
                                证件:<span>{{
                                  this.attendancePerson.idCard
                                }}</span>
                              </div>
                              <div class="vipka">
                                班组:<span>{{
                                  this.attendancePerson.workGroup
                                }}</span>
                              </div>
                              <div class="vipka">
                                时间:<span>{{
                                  this.attendancePerson.date
                                }}</span>
                              </div>
                              <div class="vipka"
                                   v-if="this.attendancePerson.templature > 0">
                                温度:<span>{{
                                    this.attendancePerson.templature
                                  }}
                                  ℃</span>
                              </div>
                            </div>
                            <div class="card-derection">
                              <div>入场</div>
                            </div>
                          </div>
                        </div>
                      </vipCard>
                    </div>
                  </div>
                </div>
                <div class="attendanceRate">
                  <div class="down">
                    <div class="percent">
                      <vipCard options="cardColorNone">
                        <!-- <vipCard > -->
                        <div class="headerBox"
                             slot="header">
                          <div class="headerImgname">
                            <div class="face">
                              <img alt=""
                                   style="width: 100%"
                                   :src="this.leavePerson.resource" />
                            </div>
                            <div class="card-info"
                                 style="">
                              <div class="name">
                                姓名:<span>{{ this.leavePerson.name }}</span>
                              </div>
                              <div class="vipka">
                                证件:<span>{{ this.leavePerson.idCard }}</span>
                              </div>
                              <div class="vipka">
                                班组:<span>{{
                                  this.leavePerson.workGroup
                                }}</span>
                              </div>
                              <div class="vipka">
                                时间:<span>{{ this.leavePerson.date }}</span>
                              </div>
                              <div v-if="this.leavePerson.templature > 0"
                                   class="vipka">
                                温度:<span>{{ this.leavePerson.templature }} ℃</span>
                              </div>
                            </div>
                            <div class="card-derection">
                              <div>离场</div>
                            </div>
                          </div>
                        </div>
                      </vipCard>
                    </div>
                  </div>
                </div>
              </dv-border-box-8>
            </div>
            <div>
              <dv-border-box-1>
                <div id="worktypepie">
                  <div class="bg-color-black">
                    <div>
                      <div>
                        <Echart :options="attendanceworktypeoptions"
                                id="attendanceworktype"
                                height="5.05rem"
                                width="5.75rem"></Echart>
                      </div>
                    </div>
                  </div>
                </div>
              </dv-border-box-1>
            </div>
          </div>

          <!-- 第四行数据 -->
          <div class="bototm-box">
            <dv-border-box-12>
              <div id="bottomLeft">
                <div class="bg-color-black">
                  <div class="d-flex pt-2 pl-2">
                    <span style="color: #5cd9e8">
                      <icon name="chart-bar"></icon>
                    </span>
                    <div class="d-flex">
                      <span class="fs-xl text mx-2">考勤数据统计图</span>
                    </div>
                  </div>
                  <div>
                    <div>
                      <Echart :options="tableAttandenceOptions"
                              id="bottomLeftChart"
                              height="6.25rem"
                              width="100%"></Echart>
                    </div>
                  </div>
                </div>
              </div>
            </dv-border-box-12>
            <dv-border-box-12>
              <div id="bottomLeft">
                <div class="bg-color-black">
                  <div class="d-flex pt-2 pl-2">
                    <span style="color: #5cd9e8">
                      <icon name="chart-bar"></icon>
                    </span>
                    <div class="d-flex">
                      <span class="fs-xl text mx-2">人员数据统计图</span>
                    </div>
                  </div>
                  <div>
                    <div>
                      <Echart :options="tableOptions"
                              id="bottomLeftChart"
                              height="6.25rem"
                              width="100%"></Echart>
                    </div>
                  </div>
                </div>
              </div>
            </dv-border-box-12>
          </div>
        </div>
      </div>
    </dv-full-screen-container>
  </div>
</template>

<script>
import CenterChart from "@/components/echart/center/ChartRate";
import PieCicleChart from "@/components/echart/center/PieCicleChart";
import vipCard from "@/components/Card/attendanceCard";
import Menu from "@/components/Menu";
import ProInfoCache from "@/utils/cache/ProInfoCache"
import Echart from "@/common/echart";

const synth = window.speechSynthesis;
const msg = new SpeechSynthesisUtterance();
moment.locale("zh-cn");
import moment from "moment";
export default {
  data () {
    return {
      synth,
      msg,
      loading: true,
      pro: {},
      connection: {},
      personCount: [
        {
          id: "centerRate1",
          text: "总人数",
          seriesData: [
            {
              name: "管理人员",
              value: 0,
            },
            {
              name: "劳务人员",
              value: 0,
            },
          ],
        },
        {
          id: "centerRate1",
          text: "现场人数",
          seriesData: [
            {
              name: "管理人员",
              value: 0,
            },
            {
              name: "劳务人员",
              value: 0,
            },
          ],
        },
      ],
      rate: [
        {
          id: "centerRate2",
          text: "管理人员出勤率",
          tips: 0,
          colorData: {
            textStyle: "#3fc0fb",
            series: {
              color: ["#00bcd44a", "transparent"],
              dataColor: {
                normal: "#03a9f4",
                shadowColor: "#97e2f5",
              },
            },
          },
        },
        {
          id: "centerRate3",
          text: "劳务人员出勤率",
          tips: 0,
          colorData: {
            textStyle: "#67e0e3",
            series: {
              color: ["#faf3a378", "transparent"],
              dataColor: {
                normal: "#ff9800",
                shadowColor: "#fcebad",
              },
            },
          },
        },
      ],
      worktypeoptions: {},
      cdata: {
        xData: ["1"],
        seriesData: [{ value: 10, name: "1" }],
      },
      attendanceworktypeoptions: {},
      attendanceworktypeCData: {
        xData: [],
        seriesData: [],
      },
      tableAttandenceOptions: {},
      tableOptions: {},
      tableCdata: {
        category: [],
        mcountData: [],
        wcountData: [],
        atendanceMCountData: [],
        atendanceWCountData: [],
      },
      attendancePerson: {
        name: "暂无",
        resource: "暂无",
        direction: 0,
        templature: 0,
        date: "暂无",
        workGroup: "暂无",
      },
      leavePerson: {
        name: "暂无",
        resource: "暂无",
        direction: 0,
        templature: 0,
        date: "暂无",
        workGroup: "暂无",
      },
    };
  },
  components: {
    CenterChart,
    PieCicleChart,
    Menu,
    Echart,
    vipCard,
  },
  created: function () {
    let connection = this.$op.getConnect(this);
    connection.on("person_attence", (x) => {
      console.log(x)
      if (x.pid != this.pro.Id)
        return
      if (x.direction == 0) {
        if (
          this.attendancePerson &&
          moment(x.date) >= moment(this.attendancePerson.date)
        )
          this.attendancePerson = x;
        this.handleSpeak(x.name + "进场");
      } else {
        if (this.leavePerson && moment(x.date) >= moment(this.leavePerson.date))
          this.leavePerson = x;
        this.handleSpeak(x.name + "离场");
      }
    });
  },
  mounted () {
    this.pro = ProInfoCache.getProInfo()
    this.initForm()
  },
  watch: {
    cdata: {
      handler (newData) {
        this.worktypeoptions = {
          radius: "50%",
          activeRadius: "55%",
          title: {
            text: "实名制工种分布图",
            x: "center",
          },
          color: [
            "#37a2da",
            "#32c5e9",
            "#9fe6b8",
            "#ffdb5c",
            "#ff9f7f",
            "#fb7293",
            "#e7bcf3",
            "#8378ea",
          ],
          tooltip: {
            trigger: "item",
            formatter: "{b} ({d}%)",
          },
          toolbox: {
            show: true,
          },
          calculable: true,
          legend: {
            icon: "circle",
            type: "plain",
            textStyle: {
              //图例文字的样式
              color: "#8C8C8C",
              fontSize: 12,
            },
            x: "0%", //距离左侧位置
            orient: "vertical",
            data: newData.xData,
            selectedMode: true,
          },
          series: [
            {
              /* name: "通过率统计", */
              type: "pie",
              /* radius: [10, 60], */
              /* roseType: "area", */
              radius: "50%",
              activeRadius: "55%",
              center: ["50%", "50%"],
              data: newData.seriesData,
            },
          ],
        };
      },
      immediate: true,
      deep: true,
    },
    attendanceworktypeCData: {
      handler (newData) {
        this.attendanceworktypeoptions = {
          title: {
            text: "现场工种分布图",
            x: "center",
          },
          color: [
            "#37a2da",
            "#32c5e9",
            "#9fe6b8",
            "#ffdb5c",
            "#ff9f7f",
            "#fb7293",
            "#e7bcf3",
            "#8378ea",
          ],
          tooltip: {
            trigger: "item",
            formatter: "{b} ({d}%)",
          },
          toolbox: {
            show: true,
          },
          calculable: true,
          legend: {
            data: newData.xData,
            left: "center",
            top: "bottom",
            orient: "horizontal",
          },
          series: [
            {
              name: "通过率统计",
              type: "pie",
              /* radius: [10, 60], */
              /* roseType: "area", */
              radius: "50%",
              center: ["50%", "50%"],
              data: newData.seriesData,
            },
          ],
        };
      },
      immediate: true,
      deep: true,
    },
    tableCdata: {
      handler (newData) {
        this.tableAttandenceOptions = {
          title: {
            text: "",
            subtext: "",
          },
          tooltip: {
            trigger: "axis",
          },
          legend: {
            data: ["管理考勤", "劳务考勤"],
          },
          toolbox: {
            show: true,
            feature: {
              dataZoom: {
                yAxisIndex: "none",
              },
              dataView: { readOnly: false },
              magicType: { type: ["line", "bar"] },
              restore: {},
              saveAsImage: {},
            },
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: newData.category,
          },
          yAxis: {
            type: "value",
            axisLabel: {
              formatter: "{value}",
            },
          },
          series: [
            {
              name: "管理考勤",
              type: "line",
              data: newData.atendanceMCountData,
              markPoint: {
                data: [
                  { type: "max", name: "最大值" },
                  { type: "min", name: "最小值" },
                ],
              },
              markLine: {
                data: [{ type: "average", name: "平均值" }],
              },
            },
            {
              name: "劳务考勤",
              type: "line",
              data: newData.atendanceWCountData,
              markPoint: {
                data: [{ name: "周最低", value: 2, xAxis: 1, yAxis: 1.5 }],
              },
              markLine: {
                data: [
                  { type: "average", name: "平均值" },
                  [
                    {
                      symbol: "none",
                      x: "90%",
                      yAxis: "max",
                    },
                    {
                      symbol: "circle",
                      label: {
                        normal: {
                          position: "start",
                          formatter: "最大值",
                        },
                      },
                      type: "max",
                      name: "最高点",
                    },
                  ],
                ],
              },
            },
          ],
        };
        this.tableOptions = {
          title: {
            text: "",
            subtext: "",
          },
          tooltip: {
            trigger: "axis",
          },
          legend: {
            data: ["管理人员数", "劳务人员数"],
          },
          toolbox: {
            show: true,
            feature: {
              dataZoom: {
                yAxisIndex: "none",
              },
              dataView: { readOnly: false },
              magicType: { type: ["line", "bar"] },
              restore: {},
              saveAsImage: {},
            },
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: newData.category,
          },
          yAxis: {
            type: "value",
            axisLabel: {
              formatter: "{value}",
            },
          },
          series: [
            {
              name: "管理人员数",
              type: "line",
              data: newData.mcountData,
              markPoint: {
                data: [
                  { type: "max", name: "最大值" },
                  { type: "min", name: "最小值" },
                ],
              },
              markLine: {
                data: [{ type: "average", name: "平均值" }],
              },
            },
            {
              name: "劳务人员数",
              type: "line",
              data: newData.wcountData,
              markPoint: {
                data: [{ name: "周最低", value: 2, xAxis: 1, yAxis: 1.5 }],
              },
              markLine: {
                data: [
                  { type: "average", name: "平均值" },
                  [
                    {
                      symbol: "none",
                      x: "90%",
                      yAxis: "max",
                    },
                    {
                      symbol: "circle",
                      label: {
                        normal: {
                          position: "start",
                          formatter: "最大值",
                        },
                      },
                      type: "max",
                      name: "最高点",
                    },
                  ],
                ],
              },
            },
          ],
        };
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    onProInfoChange (e) {
      this.pro = e;
      this.initForm()
    },
    initForm () {
      this.changeNumber();
      this.changeTiming();
      this.cancelLoading();
    },
    /** 语音播报的函数**/
    handleSpeak (e) {
      console.log(e);
      this.msg.text = e;
      this.msg.lang = "zh-CN";
      this.msg.volume = "1";
      this.msg.rate = 1;
      this.msg.pitch = 1;
      this.synth.speak(this.msg);
    },
    // 根据自己的业务情况修改
    changeTiming () {
      setInterval(() => {
        this.changeNumber();
      }, 1000 * 60 * 5);
    },
    loadLastAttendance () {
      this.$http
        .post("/PersonelManage/Attendance/GetLastAttandence", {
          ProId: this.pro.Id,
        })
        .then((resJson) => {
          if (resJson.Data && resJson.Data.length > 0) {
            resJson.Data.forEach((x) => {
              if (x.direction == 0) this.attendancePerson = x;
              else {
                this.leavePerson = x;
              }
            });
          }
        });
    },
    changeNumber () {
      let mdc = 0;
      let wdc = 0;

      this.loadLastAttendance()
      this.$http.post("/PersonelManage/Attendance/GetCurrentCount", {
        ProId: this.pro.Id,
      })
        .then((resJson) => {
          if (resJson.Success) {
            this.personCount[0].seriesData[0].value = resJson.Data.MCount + mdc;
            this.personCount[0].seriesData[1].value = resJson.Data.WCount + wdc;
            this.personCount[1].seriesData[0].value =
              resJson.Data.OnSiteMCount + mdc;
            this.personCount[1].seriesData[1].value =
              resJson.Data.OnSiteWCount + wdc;
            this.rate[0].tips = Number(
              (
                (this.personCount[1].seriesData[0].value /
                  this.personCount[0].seriesData[0].value) *
                100
              ).toFixed(2)
            );
            let rateW = Number(
              (
                (this.personCount[1].seriesData[1].value /
                  this.personCount[0].seriesData[1].value) *
                100
              ).toFixed(2)
            );
            if (rateW == 0 && resJson.Data.OnSiteWCount > 0) rateW = 1;
            this.rate[1].tips = rateW;
          }
        });
      this.$http
        .post("/PersonelManage/Attendance/GetTypeCount", {
          ProId: this.pro.Id,
        })
        .then((resJson) => {
          if (resJson.Success) {
            this.cdata.xData = [];
            this.cdata.seriesData = [];
            this.attendanceworktypeCData.xData = [];
            this.attendanceworktypeCData.seriesData = [];
            for (let index = 0; index < resJson.Data.length; index++) {
              const element = resJson.Data[index];
              if (element.WorkType === 0) {
                this.cdata.xData.push("未知工种");
                this.cdata.seriesData.push({
                  value: element.Count + mdc,
                  name: "未知工种:" + (element.Count + mdc),
                });
                if (element.AttendanceCount >= 0) {
                  this.attendanceworktypeCData.xData.push("未知工种");
                  this.attendanceworktypeCData.seriesData.push({
                    value: element.AttendanceCount + mdc,
                    name: "未知工种:" + (element.AttendanceCount + mdc),
                  });
                }
              } else {
                this.cdata.xData.push(element.Key);
                this.cdata.seriesData.push({
                  value: element.Count + mdc,
                  name: element.Key + ":" + (element.Count + mdc),
                });
                if (element.AttendanceCount >= 0) {
                  this.attendanceworktypeCData.xData.push(element.Key);
                  this.attendanceworktypeCData.seriesData.push({
                    value: element.AttendanceCount + mdc,
                    name: element.Key + ":" + (element.AttendanceCount + mdc),
                  });
                }
              }
            }
          }
        });
      this.$http
        .post("/PersonelManage/Attendance/GetLogsAttendance", {
          ProId: this.pro.Id,
        })
        .then((resJson) => {
          this.tableCdata.category = [];
          this.tableCdata.mcountData = [];
          this.tableCdata.wcountData = [];
          if (resJson.Success) {
            resJson.Data.forEach((x) => {
              this.tableCdata.category.push(x.Key);
              this.tableCdata.mcountData.push(x.MCount);
              this.tableCdata.wcountData.push(x.WCount);
              this.tableCdata.atendanceMCountData.push(x.AttendanceMCount);
              this.tableCdata.atendanceWCountData.push(x.AttendanceWCount);
            });
          }
        });
    },
    cancelLoading () {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/index.scss";
.attendanceRate {
  padding: 0.0625rem;
  /* height: 5rem; */
  min-width: 6.05rem;

  .down {
    .percent {
      .cardColorNone {
        .headerBox {
          .headerImgname {
            .face {
              padding: 25px;
              width: 1.75rem;
            }
            .card-info {
              top: 14%;
              left: 33%;
              position: absolute;
              font-size: 18px;
              letter-spacing: 1px;
              color: rgb(252, 252, 253);
              font-family: "Open Sans", sans-serif;
              z-index: 100;
              line-height: 25px;
            }
            .card-derection {
              top: 0;
              right: 5%;
              position: absolute;
              font-size: 18px;
              letter-spacing: 1px;
              color: rgb(10 10 10);
              font-family: "Open Sans", sans-serif;
              z-index: 100;
              line-height: 25px;
              background: #1a5cd7;
              padding: 4px 11px;
            }
          }
        }
      }
    }
  }
}
.down {
  padding: 0.07rem 0.05rem;
  padding-bottom: 0;
  /*   width: 100%; */
  display: flex;
  /* height: 5.1875rem; */
  justify-content: space-between;
  .bg-color-black {
    border-radius: 0.0625rem;
  }
  .ranking {
    padding: 0.125rem;
    /*  width: 59%; */
  }
  .percent {
    /*  width: 40%; */
    display: flex;
    flex-wrap: wrap;
    .item {
      /* width: 50%; */
      /*   height: 1.5rem; */
      span {
        margin-top: 0.0875rem;
        display: flex;
        justify-content: center;
      }
    }
    .water {
      width: 100%;
    }
  }
}
</style>
<template>
  <div :id="id" :class="className" :style="{ height: height, width: width }" />
</template>
<script>
import tdTheme from "./theme.json"; // 引入默认主题
import echartMixins from "@/utils/resizeMixins";

export default {
  name: "echart",
  mixins: [echartMixins],
  props: {
    className: {
      type: String,
      default: "chart"
    },
    id: {
      type: String,
      default: "chart"
    },
    width: {
      type: String,
      default: "100%"
    },
    height: {
      type: String,
      default: "2.5rem"
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      chart: null,
      interval: {}
    };
  },
  watch: {
    options: {
      handler(options) {
        // 设置true清空echart缓存
        this.chart.setOption(options, true);
        this.setDispatchAction(this.chart, this.options);
      },
      deep: true
    }
  },
  mounted() {
    this.$echarts.registerTheme("tdTheme", tdTheme); // 覆盖默认主题
    this.initChart();
  },
  methods: {
    initChart() {
      // 初始化echart
      this.chart = this.$echarts.init(this.$el, "tdTheme");
      this.chart.setOption(this.options, true);
      this.setDispatchAction(this.chart, this.options);
    },
    setDispatchAction(chart, option) {
      // 动态显示tootip
      var faultByHourIndex = 0; //播放所在下标
      let lastIndex = 0;
      setInterval(function() {
        //使得tootip每隔三秒自动显示
        chart.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: lastIndex
        });
        chart.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: faultByHourIndex
        });
        chart.dispatchAction({
          type: "showTip", // 根据 tooltip 的配置项显示提示框。
          seriesIndex: 0,
          dataIndex: faultByHourIndex
        });
        lastIndex = faultByHourIndex;
        faultByHourIndex = faultByHourIndex + 1;
        if (faultByHourIndex >= option.series[0].data.length) {
          faultByHourIndex = 0;
        }
      }, 3000);
    }
  }
};
</script>

<style></style>

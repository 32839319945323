<template>
  <div class="hm-balance-card">
    <div class="box" :class="options" name="cardColorTwo" @tap="onCard">
      <div class="trimStrip" id="trimStrip"></div>
      <div class="">
        <slot name="header"></slot>
      </div>
      <div class="">
        <slot name="middle"></slot>
      </div>
      <div class="">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "HmBalanceCard",
  props: {
    options: {
      type: String,
      default: "cardColorNone", //.cardColorOne | cardColorTwo | cardColorThree | cardColorNone
    },
  },
  data() {
    return {};
  },
  mounted() {
    this.$nextTick(function () {
      let dom = document.getElementById("trimStrip");
      setTimeout(() => {
        dom.style.width = "45px";
        dom.style.left = "510px";
      }, 100);
    });
  },
  methods: {
    onCard() {
      this.$emit("on-card", true);
    },
  },
};
</script>
<style lang="less" scoped>
.hm-balance-card {
  padding: 2px 4px;
  .box {
    border-radius: 30upx;
    overflow: hidden;
    position: relative;
    background-size: cover;
    height: 2.35rem;
    width: 5.75rem;
    border-radius: 25px;
    border: 25px;
  }
}
.cardColorOne {
  background: linear-gradient(to left top, #0e1529 0%, #614c4c 100%);
}
.cardColorTwo {
  background: linear-gradient(to left top, #bb8d09 0%, #ffd04c 100%);
}
.cardColorThree {
  background: url("http://www.eleve.cn/houminhua/zs.png");
  background-color: #000;
}
.cardColorNone {
  background: linear-gradient(to left top, #0e1529 0%, #614c4c 100%);
}
.trimStrip {
  content: " ";
  position: absolute;
  background: #fff;
  top: 0;
  height: 2.2rem;
  width: 20px;
  left: -100px;
  opacity: 0.3;
  transition: all 0.6s ease-out;
  transform: skewX(-30deg);
}
</style>
<template>
  <div>
    <!-- 通过率/达标率 -->
    <Echart
      :options="options"
      :id="id"
      :height="height"
      :width="width"
    ></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    id: {
      type: String,
      required: true,
      default: "chartRate",
    },
    seriesData: {
      type: Array,
      required: false,
    },
    text: {
      type: String,
      required: false,
      default: "",
    },
    unit: {
      type: String,
      required: false,
      default: "",
    },
    showlegend: {
      type: Boolean,
      required: false,
      default: false,
    },
    height: {
      type: String,
      required: false,
      default: "2.55rem",
    },
    width: {
      type: String,
      required: false,
      default: "2.91rem",
    },
    showCenterData: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  watch: {
    // tips 是会变更的数据，所以进行监听
    seriesData: {
      handler(newData) {
        let data = [];
        let total = 0;
        newData.forEach((item) => {
          total += item.value;
        });
        if (!this.showCenterData) {
          total = "";
        }
        if (this.showlegend)
          newData.forEach((item) => {
            data.push(item.name);
          });

        this.options = {
          title: {
            zlevel: 0,
            text: ["{name|" + this.text + "}", "{value|" + total + "}"].join(
              "\n"
            ),
            top: "35%",
            left: "50%",
            textAlign: "center",
            textStyle: {
              rich: {
                value: {
                  color: "#faf3a378",
                  fontSize: 30 / 24 + "rem",
                  lineHeight: 30,
                },
                name: {
                  color: "#909399",
                  lineHeight: 20,
                },
              },
            },
          },
          color: ["#2FC25B", "#FACC14", "#1890FF", "#13C2C2"],
          series: [
            {
              name: "",
              type: "pie",
              center: ["50%", "50%"],
              radius: ["50%", "70%"],
              emphasis: {
                itemStyle: {
                  borderWidth: 0,
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
              data: newData,
            },
          ],
        };
        if (this.showlegend) {
          this.options.tooltip = {
            trigger: "item",
            formatter: "{b} : {c} " + this.unit,
          };
          this.options.legend = {
            icon: "circle",
            type: "plain",
            textStyle: {
              //图例文字的样式
              color: "#8C8C8C",
              fontSize: "0.2rem",
            },
            x: "0%", //距离左侧位置
            orient: "vertical",
            data: data,
            selectedMode: true,
          };
          this.options.grid = {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          };
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
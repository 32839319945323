<template>
  <div>
    <!-- 通过率/达标率 -->
    <Echart
      :options="options"
      :id="id"
      height="2.25rem"
      width="2.91rem"
    ></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    id: {
      type: String,
      required: true,
      default: "chartRate",
    },
    tips: {
      type: Number,
      required: true,
      default: 50,
    },
    text: {
      type: String,
      required: false,
      default: "",
    },
    colorObj: {
      type: Object,
      default: function () {
        return {
          textStyle: "#3fc0fb",
          series: {
            color: ["#00bcd44a", "transparent"],
            dataColor: {
              normal: "#03a9f4",
              shadowColor: "#97e2f5",
            },
          },
        };
      },
    },
  },
  watch: {
    // tips 是会变更的数据，所以进行监听
    tips: {
      handler(newData) {
        this.options = {
          title: {
            zlevel: 0,
            text: [
              "{name|" + this.text + "}",
              "{value|" + newData * 1 + "%}",
            ].join("\n"),
            top: "35%",
            left: "50%",
            textAlign: "center",
            textStyle: {
              rich: {
                value: {
                  color: "#faf3a378",
                  fontSize: 30,
                  fontWeight: "bold",
                  lineHeight: 30,
                },
                name: {
                  color: "#909399",
                  lineHeight: 20,
                },
              },
            },
          },
          series: [
            {
              type: "pie",
              radius: ["75%", "80%"],
              center: ["50%", "50%"],
              hoverAnimation: false,
              color: this.colorObj.series.color,
              label: {
                normal: {
                  show: false,
                },
              },
              data: [
                {
                  value: newData,
                  itemStyle: {
                    normal: {
                      color: this.colorObj.series.dataColor.normal,
                      shadowBlur: 10,
                      shadowColor: this.colorObj.series.dataColor.shadowColor,
                    },
                  },
                },
                {
                  value: 100 - newData,
                },
              ],
            },
          ],
        };
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>